/**
 * @type {import('vanilla-cookieconsent').CookieConsentConfig}
 */

let mobile;

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    mobile = true;
} else {
    mobile = false;
}
let firstConsent = false;
const config = {
    cookie: {
        expiresAfterDays: (acceptType) => {
            return acceptType === 'all' ? 365 : 182;
        },
    },

    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'middle center',
            equalWeightButtons: false,
            flipButtons: false,
        },
        preferencesModal: {
            layout: 'box',
            position: 'middle center',
            equalWeightButtons: false,
            flipButtons: !mobile,
        },
    },

    revision: 2,

    onFirstConsent: (cookie) => {
        consent(cookie);
        logConsent();
        firstConsent = true;
    },

    onConsent: (cookie) => {
        if (typeof consent_defaults === 'undefined') {
            consent(cookie);
        } else if (consent_defaults == 'consent_not_set') {
            if (!firstConsent) {
                restoreConsent();
            }
        }
    },

    onModalShow: (modal) => {
        if (modal.modalName == 'preferencesModal') {
            if (typeof hj !== 'undefined') {
                hj('event', 'consent_show_preference_modal');
            }
            dataLayer.push({ event: 'consent_show_preference_modal' });
        }
    },

    onModalHide: (modal) => {},

    onChange: (cookie, changedCategories, changedServices) => {
        consent(cookie);
        logConsent(true);
    },

    categories: {
        necessary: {
            readOnly: true,
            enabled: true,
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/,
                    },
                ],
            },
            enabled: true,
        },
        ads: {
            // readOnly: true,
            enabled: false,
        },
    },

    language: {
        default: 'nl',
        autoDetect: 'browser',
        translations: {
            // nl: url('translations/cookie.nl.json'),
            nl: {
                consentModal: {
                    title:
                        '<img src="' +
                        window.file_asset_url +
                        '/img/cookie-bite-sharp-thin.svg" class="inline-block mr-2" height="20px" width="20px" /> <span>Cookies</span>',
                    description:
                        'Onze website werkt dankzij functionele cookies optimaal en we plaatsen analytische cookies om onze website elke dag een beetje beter te kunnen maken. Met persoonlijke cookies wordt het mogelijk om onze klanten persoonlijke en relevante content te laten zien. Wil je onze website optimaal kunnen gebruiken? Door op \'Ik begrijp het\' te klikken, accepteer jij onze cookies. Klik je op <button class="mr-1 text-blue-600" type="button" data-cc="accept-custom">weigeren</button>? Dan plaatsen wij alleen functionele en analytische cookies.</div>',
                    acceptAllBtn: 'Ik begrijp het',
                    showPreferencesBtn: 'Voorkeuren',
                    footer: '<a target="_blank" href="/privacy">Privacy</a><a target="_blank" href="/algemene-voorwaarden">Voorwaarden</a>',
                },
                preferencesModal: {
                    title: 'Voorkeuren',
                    acceptAllBtn: 'Accepteer alles',
                    acceptNecessaryBtn: 'Weiger alles',
                    closeIconLabel: 'Sluit',
                    savePreferencesBtn: 'Voorkeuren opslaan',
                    sections: [
                        {
                            title: 'Privacy voorkeuren cookies',
                            description:
                                'Geef hier jouw persoonlijke privacy voorkeuren aan. Onze website werkt dankzij functionele cookies optimaal en we plaatsen analytische cookies om onze website elke dag een beetje beter te kunnen maken. Met persoonlijke cookies wordt het mogelijk om onze klanten persoonlijke en relevante content te laten zien.',
                        },
                        {
                            title: 'Strikt noodzakelijke cookies',
                            description:
                                'Onmisbaar voor optimale functionaliteit bij Doorax gebruiken we wat we noemen "strikt noodzakelijke cookies" - die zijn als het onderhoudsmiddel voor je cilinderslot. Ze zorgen ervoor dat alles soepel verloopt als je onze website bezoekt. Denk aan het navigeren door pagina\'s en veilig inloggen. Geen zorgen, deze cookies verzamelen geen persoonlijke gegevens en worden automatisch geplaatst als je onze site bezoekt. Ze zijn nodig om alles te laten werken zoals het hoort.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Prestatie- en analysecookies',
                            description:
                                'Voor een gebruiksvriendelijke shopervaring bij Doorax maken we ook gebruik van prestatie- en analysecookies - ze zijn net als ons team, altijd op zoek naar een betere klantervaring. Deze cookies helpen ons te begrijpen hoe bezoekers onze website gebruiken, zodat we deze kunnen verbeteren. Geen zorgen, jouw privacy staat voorop en we gebruiken deze gegevens alleen om onze website beter te maken voor jou en anderen. Dus terwijl jij geniet van onze gebruiksvriendelijke site, werken wij achter de schermen aan een nóg betere ervaring voor andere bezoekers!',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description',
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: 'google.com',
                                        desc: 'Google Analytics',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.nl',
                                        desc: 'Google Analytics',
                                    },
                                    {
                                        name: '_hjSession_*',
                                        domain: 'hotjar.com',
                                        desc: 'Analytics',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Advertentie- en targetingcookies',
                            description:
                                'Op maat gemaakt voor jou bij Doorax gebruiken we ook advertentie- en targetingcookies - ze zijn net als onze cilindersloten, op maat gemaakt voor jou. Deze cookies helpen ons om advertenties te tonen die relevant zijn voor jouw interesses. Maak je geen zorgen, jouw privacy is belangrijk voor ons en we delen geen persoonlijke gegevens met adverteerders zonder jouw toestemming.',
                            linkedCategory: 'ads',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description',
                                },
                                body: [
                                    {
                                        name: 'MUID',
                                        domain: '.bing.com',
                                        desc: 'Advertisement',
                                    },
                                    {
                                        name: 'ANONCHK',
                                        domain: '.c.clarity.ms',
                                        desc: 'Advertisement',
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
            en: {
                consentModal: {
                    title: '<img src="/img/cookie-bite-sharp-thin.svg" class="inline-block mr-2" height="20px" width="20px" /> <span>Cookies</span>',
                    description:
                        'Our website works optimally thanks to functional cookies, and we place analytical cookies to improve our website a little more each day. Personal cookies allow us to show our customers personalized and relevant content. Do you want to use our website optimally? By clicking \'I understand,\' you accept our cookies. If you click <button class="mr-1 text-blue-600" type="button" data-cc="accept-custom">reject</button>, we will only place functional and analytical cookies.</div>',
                    acceptAllBtn: 'I understand',
                    showPreferencesBtn: 'Preferences',
                    footer: '<a target="_blank" href="/privacy">Privacy</a><a target="_blank" href="/algemene-voorwaarden">Terms</a>',
                },
                preferencesModal: {
                    title: 'Preferences',
                    acceptAllBtn: 'Accept All',
                    acceptNecessaryBtn: 'Reject All',
                    closeIconLabel: 'Close',
                    savePreferencesBtn: 'Save Preferences',
                    sections: [
                        {
                            title: 'Privacy Preferences for Cookies',
                            description:
                                'Specify your personal privacy preferences here. Our website works optimally thanks to functional cookies, and we place analytical cookies to improve our website a little more each day. Personal cookies allow us to show our customers personalized and relevant content.',
                        },
                        {
                            title: 'Strictly Necessary Cookies',
                            description:
                                'Essential for optimal functionality, at Doorax, we use what we call "strictly necessary cookies" - they are like the lubricant for your door lock. They ensure that everything runs smoothly when you visit our website. Think of navigating pages and logging in securely. Don\'t worry, these cookies do not collect personal data and are automatically placed when you visit our site. They are necessary to make everything work as it should.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Performance and Analytics Cookies',
                            description:
                                "For a user-friendly shopping experience at Doorax, we also use performance and analytics cookies - they are like our team, always striving for a better customer experience. These cookies help us understand how visitors use our website so we can improve it. Don't worry, your privacy is paramount, and we only use this data to make our website better for you and others. So while you enjoy our user-friendly site, we're working behind the scenes to provide an even better experience for other visitors!",
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description',
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: 'google.com',
                                        desc: 'Google Analytics',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.nl',
                                        desc: 'Google Analytics',
                                    },
                                    {
                                        name: '_hjSession_*',
                                        domain: 'hotjar.com',
                                        desc: 'Analytics',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Advertising and Targeting Cookies',
                            description:
                                "Customized for you, at Doorax, we also use advertising and targeting cookies - they are like our door locks, tailor-made for you. These cookies help us show ads that are relevant to your interests. Don't worry, your privacy is important to us, and we do not share personal data with advertisers without your consent.",
                            linkedCategory: 'ads',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description',
                                },
                                body: [
                                    {
                                        name: 'MUID',
                                        domain: '.bing.com',
                                        desc: 'Advertisement',
                                    },
                                    {
                                        name: 'ANONCHK',
                                        domain: '.c.clarity.ms',
                                        desc: 'Advertisement',
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        },
    },
    // disablePageInteraction: mobile,
    disablePageInteraction: true,
};

function consent(cookie) {
    let consentType;

    const preferences = $CookieConsent.getUserPreferences();

    if (preferences.acceptedCategories.length == 3) {
        consentType = 'consent_allow_all';
    } else if (preferences.acceptedCategories.length == 2) {
        if (preferences.acceptedCategories[1] == 'ads') {
            consentType = 'consent_only_ads';
        } else {
            consentType = 'consent_only_analytics';
        }
    } else if (preferences.acceptedCategories.length == 1) {
        consentType = 'consent_deny_all';
    }

    if (typeof hj !== 'undefined') {
        hj('event', consentType);
    }
    dataLayer.push({ event: consentType });
}

function restoreConsent() {
    // Retrieve all the fields
    const cookie = $CookieConsent.getCookie();
    const preferences = $CookieConsent.getUserPreferences();

    const userConsent = {
        consentId: cookie.consentId,
        acceptType: preferences.acceptType,
        acceptedCategories: preferences.acceptedCategories,
        rejectedCategories: preferences.rejectedCategories,
    };

    axios
        .post('/consent/restore', {
            body: JSON.stringify(userConsent),
        })
        .catch(function (error) {
            // Sentry.captureException(error);
        });
}

function logConsent(update = false) {
    // Retrieve all the fields
    const cookie = $CookieConsent.getCookie();
    const preferences = $CookieConsent.getUserPreferences();

    const userConsent = {
        consentId: cookie.consentId,
        acceptType: preferences.acceptType,
        acceptedCategories: preferences.acceptedCategories,
        rejectedCategories: preferences.rejectedCategories,
    };

    // Send the data to your backend
    axios
        .post('/consent/log', {
            body: JSON.stringify(userConsent),
        })
        .catch(function (error) {
            // Sentry.captureException(error);
        });
}

function url(value, param) {
    if (param) {
        return `${base_url}/${value}/${param}`;
    } else {
        return `${base_url}/${value}`;
    }
}


export default config;
