<template>
    <Navbar />

    <slot></slot>

    <AppFooter v-if="loadFooter" />

    <FlashMessages v-if="flash" />

    <Wizard v-if="showWizard" />

    <Comparison v-if="comparisonList" />

    <MobileChat v-if="isMobile" />

    <Transition name="fade">
        <div id="trengo-widget-fake" class="hidden-medium" v-if="loaded && !chatOpened" @click="loadChat">
            <div class="fixed bottom-6 right-4 z-50">
                <div
                    class="hidden bg-dgreen rounded-2xl absolute right-20 px-4 py-2 w-44 pl-4 text-white font-semibold bottom-3 md:flex items-center cursor-pointer"
                    style="font-size: 14px !important"
                >
                    <fa :icon="['fas', 'circle']" class="text-xs h-3 mr-2 text-green-400 rounded-full p-0.5" />
                    <span>Stel hier je vraag</span>
                </div>
                <div
                    class="h-16 w-16 bg-dgreen opacity-100 group transform transition-scale ease-in-out duration-200 px-4 p-2 rounded-3xl shadow-overallxl cursor-pointer z-50 flex items-center justify-center"
                >
                    <fa
                        :icon="['fad', 'comments']"
                        v-if="!showChat"
                        class="text-white mx-auto group-hover:scale-110 transition-all"
                        style="font-size: 1.6rem"
                    />
                    <Transition name="fade">
                        <fa
                            :icon="['fad', 'spinner-third']"
                            class="text-white w-7 h-7 mx-auto mt-1.5 ml-0.5 fa-spin"
                            v-if="showChat"
                        />
                    </Transition>
                </div>
            </div>
        </div>
    </Transition>

    <Trengo v-if="showChat == true" />

    <div
        class="bg-orange-100 border-orange-500 bg-yellow-100 border-yellow-500 bg-red-100 border-red-500 bg-blue-100 border-blue-500 bg-blue-100 border-purple-600 h-5 w-5 border-gray-300 rounded text-blue-600 transition focus:ring-0 hover:border-gray-600 grid grid-cols-12 col-span-2 hover:border-gray-400"
    ></div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3'; // or @inertiajs/inertia-vue3 depending on your setup
import { inject, onMounted, onUnmounted } from 'vue';

const isMobile = inject('isMobile');

import AppFooter from '@/Layouts/Footer.vue';
import Navbar from '@/Layouts/Navbar.vue';
import { computed, defineAsyncComponent, ref } from 'vue';

const inBrowser = typeof window !== 'undefined';

// Lazy load all non-critical components
const MobileChat = defineAsyncComponent(() => import('@/Blocks/MobileChat.vue'));
const Comparison = defineAsyncComponent(() => import('@/Components/ComparisonList.vue'));
const FlashMessages = defineAsyncComponent(() => import('@/Components/FlashMessages.vue'));
const Wizard = defineAsyncComponent(() => import('@/Components/Wizard.vue'));
const Trengo = defineAsyncComponent({
    loader: () => import('@/Plugins/Trengo.vue'),
    suspensible: true, // Add suspense support
    delay: 200, // Add small delay before loading to prevent flashing
});

const showCookie = ref(false);
const showWizard = ref(false);
const showChat = ref(false);
const loaded = ref(false);
const loadFooter = ref(false);

// Page props
const comparisonList = computed(() => usePage().props.comparisonList);
const flash = usePage().props.flash;
const chatOpened = usePage().props.chat_opened;

const mobileChatOpened = ref(false);

defineProps({
    withoutContainer: Boolean,
});

onMounted(() => {
    // Only add critical event listeners immediately
    window.mitt.on('open-chat', loadChat);
    window.mitt.on('mobile-chat-opened', flagChatOpened);
    loaded.value = true;

    // Defer non-critical initialization
    setTimeout(() => {
        if (inBrowser) {
            initializeScrollHandler();
            initializeKeyboardHandler();
            initializeWizardHandler();
        }
    }, 0);
});

function flagChatOpened() {
    mobileChatOpened.value = true;
}

function loadChat() {
    if (chatOpened || mobileChatOpened.value) {
        window.Trengo.Api.Widget.open('chat');
    } else {
        showChat.value = true;
        axios.get('/api/chat/opened');
    }
}

onUnmounted(() => window.mitt.off('open-chat', loadChat));

// Split initialization functions
function initializeScrollHandler() {
    window.addEventListener('scroll', handleScroll);
    if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
        loadFooter.value = true;
    }
}

function initializeKeyboardHandler() {
    document.addEventListener('keypress', (event) => {
        if (event.key == 'c') {
            showCookie.value = true;
        }
    });
}

function initializeWizardHandler() {
    window.mitt.on('open-wizard', (evt) => {
        showWizard.value = true;

        // Defer DOM manipulation
        requestAnimationFrame(() => {
            const chat = document.getElementsByClassName('TrengoOnlineStatus__iframe');
            if (chat?.[0]) {
                chat[0].style.display = 'none';
            }

            if ('ontouchstart' in document.documentElement) {
                const chatLauncher = document.getElementsByClassName('TrengoWidgetLauncher__iframe');
                if (chatLauncher?.[0]) {
                    chatLauncher[0].style.bottom = '80px';
                }
                document.body.style.position = 'fixed';
                document.body.style.overflow = 'hidden';
            }
        });
    });
}

function handleScroll() {
    if (window.scrollY > 100 || !inBrowser) {
        loadFooter.value = true;
    }
}
</script>
