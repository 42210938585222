<template>
    <Link
        :href="url('bewaard')"
        class="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 focus:outline-hidden relative"
    >
        <!-- <span
            class="rounded-full text-white bg-dgreen text-xs px-2 py-0.5 z-10 font-bold absolute right-1 top-0 -m-2"
            v-if="wishlistStore.wishlist.count"
            >{{ wishlistStore.wishlist.count }}</span
        > -->
        <span class="sr-only">Bewaard</span>
        <fa :icon="['fal', 'heart']" class="text-xl h-5 opacity-80" />
    </Link>

    <div class="inline-block">
        <button
            class="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 focus:outline-hidden relative"
            @click="openCartSlide"
        >
            <span
                class="rounded-full text-white bg-dgreen text-xs px-2 py-0.5 z-10 font-bold absolute right-1 top-0 -m-2"
                v-if="cartStore.cartData?.count"
                >{{ cartStore.cartData?.count }}</span
            >
            <fa :icon="['fal', 'shopping-cart']" class="text-xl h-5 w-5" />
        </button>
        <SlideCart v-if="cartStore.isOpen" />
    </div>
</template>

<script setup>
import { useCartStore } from '@/stores/cartStore';
import { usePage } from '@inertiajs/vue3';
import { computed, defineAsyncComponent, onMounted, onUnmounted } from 'vue';
// import { useWishlistStore } from '../stores/wishlistStore';d
const gtag = computed(() => usePage().props.flash.gtag);
const inBrowser = typeof window !== 'undefined';
const cartStore = useCartStore();
// const wishlistStore = useWishlistStore();

const SlideCart = defineAsyncComponent(() => import('@/Components/SlideCart.vue'));

onMounted(() => {
    cartStore.fetchCartData();
    // wishlistStore.fetchWishlistData();
    if (inBrowser) {
        window.mitt.on('close-slideover', () => {
            setTimeout(() => {
                cartStore.closeCart();
            }, 200);
        });
        window.mitt.on('openCartSlide', openCartSlide);
    }
});

onUnmounted(() => {
    window.mitt.off('openCartSlide', openCartSlide);
});

function openCartSlide() {
    cartStore.openCart();

    if (gtag?.value) {
        let parsedGtag = JSON.parse(JSON.stringify(gtag.value));
        dataLayer.push({ ecommerce: null });
        dataLayer.push(parsedGtag);
        if (typeof hj !== 'undefined') {
            hj('event', 'AddToCart');
        }
    } else {
        if (typeof hj !== 'undefined') {
            hj('event', 'OpenCart');
        }
    }
}
</script>
