import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        settings: null,
        menu: null,
        kiyoh: null,
        sale: null,
        user: null,
        // Add other shared data you want to persist
    }),

    actions: {
        initialize(data) {
            // Check if data exists before trying to access properties
            if (!data) return;
            
            // Set initial data from Inertia props with safe access
            if (data.settings) this.settings = data.settings
            if (data.menu) this.menu = data.menu
            if (data.kiyoh) this.kiyoh = data.kiyoh
            if (data.sale) this.sale = data.sale
            if (data.user) this.user = data.user
            // Add other properties as needed
        },
        
        // Add a getter for authentication state
        isAuthenticated() {
            return this.user !== null
        }
    },
});
