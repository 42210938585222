<template>
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 467.23 86.3"
    >
        <title id="logoTitle">Doorax</title>
        <desc id="logoDesc">Doorax.nl | Cilindersloten | Smart locks</desc>
        <defs>
            <clipPath id="clip-path">
                <path class="cls-1" d="M.61,32.71l18.56-8v48a52.94,52.94,0,0,1-5.66-7.14A78.66,78.66,0,0,1,.61,32.71Z"/>
            </clipPath>
            <linearGradient id="linear-gradient" x1="-555.26" y1="924.1" x2="-552.07" y2="924.1" gradientTransform="matrix(20.25, -20.25, -20.25, -20.25, 29970.01, 7536.86)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#1059a0"/>
                <stop offset="1" stop-color="#1f74ba"/>
            </linearGradient>
            <clipPath id="clip-path-2">
                <path class="cls-1" d="M45.06,20.31c-1.59-.59-7.74-2.08-13,.2-5.75,2.68-5.75,4.66-5.75,8.53V73.89c0,2.88,1.19,6.45,2.58,7.64a57.42,57.42,0,0,0,7.94,4.76c1.78-.4,14.28-7.44,23.41-20.73A77.64,77.64,0,0,0,73,32.91C73.14,33,46.65,20.91,45.06,20.31ZM41.69,51.17a4.37,4.37,0,1,1,4.36-4.37A4.4,4.4,0,0,1,41.69,51.17Z"/>
            </clipPath>
            <clipPath id="clip-path-3">
                <path class="cls-1" d="M73.83,15.05a1.81,1.81,0,0,0-1.09-1.68l-5-2.29V4.14A2.45,2.45,0,0,0,65.3,1.66H59.35a2.45,2.45,0,0,0-2.48,2.48V6L49.72,2.65h0a30,30,0,0,0-24.11-.2L.81,13.66a1.19,1.19,0,0,0-.69,1L0,25.77l30-13.1a18.36,18.36,0,0,1,15,.1l29,13.3Z"/>
            </clipPath>
            <linearGradient id="linear-gradient-4" x1="440.39" y1="30.3" x2="467.3" y2="30.3" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#linear-gradient"/>
        </defs>
        <path class="cls-2" d="M205.29,59.46a16.68,16.68,0,0,1-23,0,16.48,16.48,0,0,1-4.5-11.7,16.15,16.15,0,0,1,4.5-11.7,16.67,16.67,0,0,1,23,0,15.82,15.82,0,0,1,4.6,11.7,16.22,16.22,0,0,1-4.6,11.7m10.8-33.9a31.3,31.3,0,0,0-53.6,22.2,31.54,31.54,0,0,0,53.6,22.3,30.25,30.25,0,0,0,9.1-22.3,30.58,30.58,0,0,0-9.1-22.2"/>
        <path class="cls-2" d="M284.08,25.56a31.3,31.3,0,0,0-53.6,22.2,31.35,31.35,0,1,0,62.7,0,29.8,29.8,0,0,0-9.1-22.2m-10.7,33.9a16.68,16.68,0,0,1-23,0,16.48,16.48,0,0,1-4.5-11.7,16.15,16.15,0,0,1,4.5-11.7,16.67,16.67,0,0,1,23,0,15.82,15.82,0,0,1,4.6,11.7,16.22,16.22,0,0,1-4.6,11.7"/>
        <path class="cls-2" d="M320.7,19.94a16.11,16.11,0,0,0-6.92,8.48V18.18H298.47V77.56h15.31V49.19c0-5.75,1.85-9.85,5.46-12.19,3.7-2.54,7.8-2.83,12.67-2.83v-16c-4.19,0-7.89-.29-11.21,1.76"/>
        <path class="cls-2" d="M385.31,18.06v7c-4.6-5.8-11-8.6-19.3-8.6-7.9,0-14.7,3-20.4,9.1a31.65,31.65,0,0,0-8.4,22.2,31.72,31.72,0,0,0,8.4,22.3,27.18,27.18,0,0,0,20.4,9c8.3,0,14.7-2.9,19.3-8.6v7h15.3V18.06Zm-4.7,41.7a17.18,17.18,0,0,1-23.4,0,16.6,16.6,0,0,1-4.6-12.1c0-4.9,1.5-8.9,4.6-12a15.53,15.53,0,0,1,11.7-4.7,15.75,15.75,0,0,1,11.7,4.7c3.2,3.1,4.7,7.1,4.7,12a16.67,16.67,0,0,1-4.7,12.1"/>
        <polygon class="cls-2" points="436.6 34.96 424.6 18.16 406.8 18.16 427.7 47.26 405.9 77.56 423.7 77.56 436.6 59.56 449.5 77.56 467.2 77.56 436.6 34.96"/>
        <g class="cls-3">
            <polygon class="cls-4" points="-41.46 42.14 37.12 -36.54 115.8 42.14 37.12 120.72 -41.46 42.14"/>
        </g>
        <g class="cls-5">
            <polygon class="cls-4" points="-41.46 42.14 37.12 -36.54 115.8 42.14 37.12 120.72 -41.46 42.14"/>
        </g>
        <g class="cls-6">
            <polygon class="cls-4" points="-41.46 42.14 37.12 -36.54 115.8 42.14 37.12 120.72 -41.46 42.14"/>
        </g>
        <path class="cls-2" d="M147.42,18.66c-6.35-6.6-14.43-9.9-24.15-9.9H101.48a6.09,6.09,0,0,0-6.14,6v56.8a6.09,6.09,0,0,0,6.14,6h21.79c9.62,0,17.7-3.3,24.15-9.9s9.72-14.7,9.72-24.5S153.86,25.16,147.42,18.66Zm-36.77,4.66h12.63a17.33,17.33,0,0,1,13.38,5.54c3.41,3.6,5.17,8.3,5.17,14.2s-1.66,10.61-5.17,14.39a17.71,17.71,0,0,1-13.38,5.45H110.65Z"/>
        <polygon class="cls-7" points="449.66 18.16 449.25 18.73 449.25 18.73 441.05 30.03 449.95 42.43 450.36 41.86 450.36 41.86 467.26 18.16 449.66 18.16"/>
    </svg>
</template>

<style>
.cls-1 {
    fill: none;
}
.cls-2 {
    fill: #353e49;
    transition: fill 1s ease;
}
.cls-3 {
    clip-path: url(#clip-path);
}
.cls-4 {
    fill: url(#linear-gradient);
}
.cls-5 {
    clip-path: url(#clip-path-2);
}
.cls-6 {
    clip-path: url(#clip-path-3);
}
.cls-7 {
    fill: url(#linear-gradient-4);
}
</style>
