import { useForm } from '@inertiajs/vue3';
import axios from 'axios';
import { defineStore } from 'pinia';

export const useCartStore = defineStore('cart', {
    state: () => ({
        cartData: {
            data: {
                items: [],
            },
            count: 0,
            total: 0,
            tax: 0,
            date: null,
            firstPickupDate: null,
            salesDiscount: null,
        },
        isLoading: false,
        isOpen: false,
        itemLoadingStates: new Map(), // Track loading state per item
    }),

    getters: {
        isEmpty: (state) => state.cartData?.data?.items?.length == 0,
        totalAmount: (state) => state.cartData?.total || 0,
        itemCount: (state) => state.cartData?.count || 0,
        isItemLoading: (state) => (itemId) => state.itemLoadingStates.get(itemId) || false,
    },

    actions: {
        setCartData(data) {
            if (!data) return;
            this.cartData = data;
        },

        openCart() {
            this.isOpen = true;
        },

        closeCart() {
            this.isOpen = false;
        },

        setItemLoading(itemId, isLoading) {
            this.itemLoadingStates.set(itemId, isLoading);
        },

        async fetchCartData() {
            this.isLoading = true;
            try {
                const response = await axios.get('/api/cart');
                if (response.data) {
                    this.setCartData(response.data);
                }
            } catch (error) {
                console.error('Error fetching cart data:', error);
                this.clearCartData();
            } finally {
                this.isLoading = false;
            }
        },

        async addToCart(productId, quantity = 1) {
            this.setItemLoading(productId, true);
            try {
                const response = await axios.post('/api/cart', {
                    productId,
                    quantity
                });
                
                if (response.data) {
                    this.setCartData(response.data);
                }
                
                return response;
            } catch (error) {
                console.error('Error adding to cart:', error);
                throw error;
            } finally {
                this.setItemLoading(productId, false);
            }
        },

        async removeFromCart(itemId) {
            this.setItemLoading(itemId, true);
            try {
                const form = useForm({
                    _method: 'DELETE'
                });
                
                await form.post(`/winkelwagen/${itemId}`)

                return form;
            } catch (error) {
                console.error('Error removing from cart:', error);
                throw error;
            } finally {
                this.setItemLoading(itemId, false);
            }
        },

        async updateQuantity(itemId, quantity) {
            this.setItemLoading(itemId, true);
            try {
                const response = await axios.put(`/api/cart/${itemId}`, {
                    quantity
                });
                
                if (response.data) {
                    this.setCartData(response.data);
                }
                
                return response;
            } catch (error) {
                console.error('Error updating quantity:', error);
                throw error;
            } finally {
                this.setItemLoading(itemId, false);
            }
        },

        clearCartData() {
            this.cartData = {
                data: {
                    items: [],
                },
                count: 0,
                total: 0,
                tax: 0,
                date: null,
                firstPickupDate: null,
                salesDiscount: null,
            };
            this.itemLoadingStates.clear();
            localStorage.removeItem('cartData');
        },
    },
}); 